import React, { useEffect, useState, useContext, useLayoutEffect } from 'react';
import Image from "./Components/Image";
import SideBar from "./Components/SideBar";
import Navbar from "./Components/Navbar";
import DetailsDrawer from "./Components/DetailsDrawer";
import DetailsModal from "./Components/DetailsModal";
import { ChooseNetworkTopics } from "./Pages/ChooseNetworkTopics";
import { Contact } from "./Pages/Contact";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
import { TermsOfUse } from "./Pages/TermsOfUse";
import { ChildSexualAbuseTerms } from "./Pages/ChildSexualAbuseTerms";

// Pages
import Login from "./Pages/Login";
import LoginNew from './Pages/LoginNew';
import Register from "./Pages/Register";
import Featured from "./Pages/Featured";
import Home from "./Pages/Home";
import Networks from "./Pages/Networks/Networks";
import Explore from "./Pages/Explore";
import Article from "./Pages/Article";
import DirectMessages from "./Pages/DirectMessages/DirectMessages.jsx";
import UserProfile from "./Pages/UserProfile";
import Wave from "./Pages/Wave";
import Invite from "./Pages/Invite";
import NetworkCreated from './Pages/NetworkCreated';
import Profile from "./Pages/Profile";
import ProfileEdit from './Pages/ProfileEdit';
import ProfileCreatorPayment from "./Pages/ProfileCreatorPayment";

import CreatorPaymentComplete from "./Pages/CreatorPaymentComplete";
// import CreatorPaymentInComplete from "./Pages/CreatorPaymentInComplete";

import PayWall from "./Pages/PayWall";
import PaymentComplete from "./Pages/PaymentComplete";
import SubscribeComplete from "./Pages/SubscribeComplete";
import StripeConnect from "./Pages/StripeConnect";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AuthContext from './store/AuthContext.js';
import { DrawerContextProvider } from './store/DrawerContext.js';
import { ModalContextProvider } from './store/ModalContext.js';
import { MessageContextProvider } from './store/MessageContext.js';
import { ArticleContextProvider } from './store/ArticleContext.js';
import { UserProfileContextProvider } from './store/UserProfileContext.js';
import { FollowerContextProvider } from './store/FollowerContext.js';
import { GlobalContextProvider } from './store/GlobalContext.js';

//importing modal scss file here, just as a caution
import './Styles/Modal.scss'
import CreateNetworkNew from './Pages/CreateNetworkNew';


function App() {

  const authCtx = useContext(AuthContext);
  const [name, setName] = useState('');


  return (
    <>
      <div className="App">
        <BrowserRouter>
          <DrawerContextProvider>
            <ModalContextProvider>
              <MessageContextProvider>
                <ArticleContextProvider>
                  <UserProfileContextProvider>
                    <FollowerContextProvider>
                      <GlobalContextProvider>
                        {authCtx.isLoggedIn && <Navbar />}
                        <div className="main-container" >
                          {/* {!dmOpen && (<SideBar /> )} */}

                          <main className="main">
                            <Routes>
                              <Route path="/" exact index element={<Navigate to='/home' />} />
                              {/* <Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login setName={setName} /> : <Navigate to='/featured' />} /> */}
                              <Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login setName={setName} /> : <Navigate to='/featured' />} />
                              <Route path="/invite" exact index element={!authCtx.isLoggedIn ? <Invite setName={setName} /> : <Navigate to='/featured' />} />
                              {/* <Route path="/register" exact element={!authCtx.isLoggedIn ? <Register/> : <Navigate to='/featured' />} /> */}
                              <Route path="/home" exact element={<Home />} />
                              <Route path="/contact" exact element={<Contact />} />
                              <Route exact path={"/privacy-policy"} element={<PrivacyPolicy />} />
                              <Route exact path={"/terms-of-use"} element={<TermsOfUse />} />
                              <Route exact path={"/standards-against-child-sexual-abuse-and-exploitation-csae"} element={<ChildSexualAbuseTerms />} />
                              <Route path="/featured" exact element={authCtx.isLoggedIn ? <Featured /> : <Navigate to='/login' />} />
                              <Route path="/explore" exact element={authCtx.isLoggedIn ? <Explore /> : <Navigate to='/login' />} />
                              <Route path="/messages" exact element={authCtx.isLoggedIn ? <DirectMessages /> : <Navigate to='/login' />} />
                              <Route path="/wave" exact element={authCtx.isLoggedIn ? <Wave /> : <Navigate to='/login' />} />
                              <Route path="/messages/:directMessageId" exact element={authCtx.isLoggedIn ? <DirectMessages /> : <Navigate to='/login' />} />
                              <Route path="/user/:userName" exact element={authCtx.isLoggedIn ? <UserProfile /> : <Navigate to='/login' />} />
                              <Route path='/network-created/:id' exact element={authCtx.isLoggedIn ? <NetworkCreated /> : <Navigate to='/login' />} />
                              <Route path="/networks" exact element={authCtx.isLoggedIn ? <Networks /> : <Navigate to='/login' />} />
                              <Route path="/networks/:networkId" exact element={authCtx.isLoggedIn ? <Networks /> : <Navigate to='/login' />} />
                              <Route path="/networks/:networkId/:tab" exact element={authCtx.isLoggedIn ? <Networks /> : <Navigate to='/login' />} />
                              <Route path="/article/:id" exact element={<Article />} />
                              <Route path="/networks/:id/paywall/:userId" exact element={<PayWall />} />
                              <Route path="/networks/:id/paywall/:userId/completed" exact element={<PaymentComplete />} />
                              <Route path="/networks/:id/paywall/:userId/subscribed" exact element={<SubscribeComplete />} />
                              <Route path="/account/setup/:userId/:source" exact element={<StripeConnect />} />

                              <Route path="/create-network" exact element={authCtx.isLoggedIn ? <CreateNetworkNew /> : <Navigate to='/login' />} />

                              <Route path="/profile" exact element={authCtx.isLoggedIn ? <Profile /> : <Navigate to='/login' />} />
                              <Route path="/profile/:id" exact element={<Profile />} />
                              <Route path="/profile/topics" exact element={authCtx.isLoggedIn ? <ChooseNetworkTopics /> : <Navigate to='/login' />} />
                              <Route path="/profile/edit" exact element={authCtx.isLoggedIn ? <ProfileEdit /> : <Navigate to='/login' />} />
                              <Route path="/profile/:userId/creatorpayment" exact element={<ProfileCreatorPayment />} />

                              <Route path="/invite/:inviteId" exact element={<Invite />} />

                              <Route path="/creators/:userId/payments/completed" exact element={<CreatorPaymentComplete />} />
                              {/* <Route path="/creators/:userId/payments/incomplete" exact element={<CreatorPaymentInComplete/>} /> */}


                            </Routes>
                          </main>
                          <DetailsDrawer />
                          <DetailsModal />
                        </div>
                      </GlobalContextProvider>
                    </FollowerContextProvider>
                  </UserProfileContextProvider>
                </ArticleContextProvider>
              </MessageContextProvider>
            </ModalContextProvider>
          </DrawerContextProvider>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
