import React, { useContext, useState } from 'react'

import NetworkImage from '../../Components/Networks/NetworkImage';
import AvatarChatImage from '../../Components/Avatar/AvatarChatImage';

//assets
import Network1 from '../../assets/img/networks/network6.png'
import TrophyGold from '../../assets/img/featured/gold-trophy.svg'
import TrophySilver from '../../assets/img/featured/silver-trophy.svg'
import TrophyBronze from '../../assets/img/featured/bronze-trophy.svg'
import CreatorCenter from '../../assets/img/featured/creator-center.svg'
import CreatorLeft from '../../assets/img/featured/creator-left.svg'
import CreatorRight from '../../assets/img/featured/creator-right.svg'

//mui
import { KeyboardArrowUpRounded } from '@mui/icons-material'
import { TooltipIntroduction } from "../TooltipIntroduction";

//context
import ModalContext from '../../store/ModalContext';
import CustomButton from '../../common/CustomButton';


const Dashboard = (props) => {

  console.log(props);

  //states
  const [dashboardOpen, setDashboardOpen] = useState(false)

  //functions
  const handleDashboard = () => {

    dashboardOpen ? setDashboardOpen(false) : setDashboardOpen(true)
  }

  const modalCtx = useContext(ModalContext)


  const handleModal = (modal, data) => {
    modalCtx.openModal()
    modalCtx.setDetails(modal, data)
  }

  return (
    <div className="dashboard-container" style={dashboardOpen
      ? { height: '100px' }
      : null
    }>
      <div className="dashboard" >
        <div className="dashboard-header">
          <h2>My Dashboard</h2>
          <KeyboardArrowUpRounded
            style={dashboardOpen
              ? { transform: 'rotate(180deg)' }
              : null
            }
            onClick={() => handleDashboard()}
            className='expand-dashboard' />
        </div>
        <div className="dash-tabs"
          style={dashboardOpen
            ? { display: 'none' }
            : null
          }>

          <div className="popular-networks">
            <h3>Your Most Popular Networks</h3>

            <div className="card-container">
              {
                props?.networks[0] != null ? <div className="card">
                  <div className="card-part-1">
                    <div className="card-img">
                      <NetworkImage coverRef={props?.networks[0].coverRef} title={props?.networks[0].name} />
                    </div>
                    <div className="card-name">{props?.networks[0].title}</div>
                  </div>
                  <div className="card-part-2">
                    <span>Followers</span>
                    <div className="followers">{props?.networks[0].subscriberCount}</div>
                  </div>
                </div> : <div className="card-create" >
                  <div className="card-part-1"  onClick={() => handleModal('create-network',{})}>+</div>
                  <div className="card-part-2"><span>CREATE<br />your network</span></div>
                </div>
              }

              {
                props?.networks[1] != null ? <div className="card">
                  <div className="card-part-1">
                    <div className="card-img">
                      <NetworkImage coverRef={props?.networks[1].coverRef} title={props?.networks[1].name} />
                    </div>
                    <div className="card-name">{props?.networks[1].title}</div>
                  </div>
                  <div className="card-part-2">
                    <span>Followers</span>
                    <div className="followers">{props?.networks[1].subscriberCount}</div>
                  </div>
                </div> : <div className="card-create">
                  <div className="card-part-1" onClick={() => handleModal('create-network',{})}>+</div>
                  <div className="card-part-2"><span>CREATE<br />your network</span></div>
                </div>
              }

              {
                props?.networks[2] != null ? <div className="card">
                  <div className="card-part-1">
                    <div className="card-img">
                      <NetworkImage coverRef={props?.networks[2].coverRef} title={props?.networks[2].name} />
                    </div>
                    <div className="card-name">{props?.networks[2].title}</div>
                  </div>
                  <div className="card-part-2">
                    <span>Followers</span>
                    <div className="followers">{props?.networks[2].subscriberCount}</div>
                  </div>
                </div> : <div className="card-create">
                  <div className="card-part-1"  onClick={() => handleModal('create-network',{})}>+</div>
                  <div className="card-part-2"><span>CREATE<br />your network</span></div>
                </div>
              }

            </div>
          </div>

          <div className="engaged-users">
            <h3>Most Engaged User</h3>

            {
              props?.mostEngaged
                ? <div className="card-container">
                  {
                    props?.mostEngaged[0] != null ? <div className="card">
                      <div className="card-part-1">
                        <div className="card-img">
                          <AvatarChatImage avatarRef={props?.mostEngaged[0].avatar} title={props?.mostEngaged[0].name} />
                        </div>
                        {/* <img className='card-img' src={Network1} alt="" /> */}
                        <div className="card-name">{props?.mostEngaged[0].name}</div>
                      </div>
                      <div className="card-part-2">
                        <img src={TrophyGold} alt="trophy" />
                      </div>
                    </div> : null
                  }

                  {
                    props?.mostEngaged[1] != null ? <div className="card">
                      <div className="card-part-1">
                        <div className="card-img">
                          <AvatarChatImage avatarRef={props?.mostEngaged[1].avatar} title={props?.mostEngaged[1].name} />
                        </div>
                        <div className="card-name">{props?.mostEngaged[1].name}</div>
                      </div>
                      <div className="card-part-2">
                        <img src={TrophySilver} alt="trophy" />
                      </div>
                    </div> : null
                  }

                  {
                    props?.mostEngaged[2] != null ? <div className="card">
                      <div className="card-part-1">
                        <div className="card-img">
                          <AvatarChatImage avatarRef={props?.mostEngaged[2].avatar} title={props?.mostEngaged[2].name} />
                        </div>
                        <div className="card-name">{props?.mostEngaged[2].name}</div>
                      </div>
                      <div className="card-part-2">
                        <img src={TrophyBronze} alt="trophy" />
                      </div>
                    </div> : null
                  }

                </div>
                : null
            }

          </div>
          <TooltipIntroduction tooltipIndex={1}>
            <div className="network-earning">
              <h3>Paid Network Earnings</h3>

              <div className="earning-container">

                <div className="earnings">
                  <div className="earning-network">
                    <div className="network-name">Full Circle Core Music</div>
                    <div className="network-earning-cash-neg">- $ 80</div>
                  </div>
                  <div className="earning">
                    <div className="network-name">Full Circle Core Music</div>
                    <div className="network-earning-cash">$ 20</div>
                  </div>
                  <div className="earning">
                    <div className="network-name">Full Circle Core Music</div>
                    <div className="network-earning-cash">$ 20</div>
                  </div>
                  <div className="more">
                    + 2 more
                  </div>
                </div>

                <div className="earnings">
                  <div className="earning-network">
                    <div className="network-name">Full Circle Core Music</div>
                    <div className="network-earning-cash-pos">+ $ 100</div>
                  </div>
                  <div className="earning">
                    <div className="network-name">Full Circle Core Music</div>
                    <div className="network-earning-cash">$ 20</div>
                  </div>
                  <div className="earning">
                    <div className="network-name">Full Circle Core Music</div>
                    <div className="network-earning-cash">$ 20</div>
                  </div>
                  <div className="more">
                    + 2 more
                  </div>
                </div>

              </div>

              <div className="become-creator">
                <div className="creator-img">
                  <img src={CreatorLeft} alt="creator" />
                  <img src={CreatorCenter} alt="creator" />
                  <img src={CreatorRight} alt="creator" />
                </div>
                <span>You're Popular!</span>
                <span>Get a 1000 followers, <br /> become a paid creator and earn from networks</span>
                <CustomButton className='f-btn-full-width' >Become a Creator</CustomButton>
              </div>
            </div>
          </TooltipIntroduction>
        </div>
      </div>
    </div>
  )
}

export default Dashboard